.tours {
    max-width: 1000px !important;
}
.blogs {
    position: static;
    max-width: 1450px;
    padding: 0px 15px;
    margin: 0 auto;
    .blog-thumb {
        position: relative;
        img {
            @extend .w-100, .h-100;
        }
        .blog-meta-top {
            @extend .pt-1;
            position: absolute;
            left: 20px;
            top: 20px;
        }
    }
    .single-blog-content {
        p {
            line-height: 1.5em;
        }
        .img-blog {
            img {
                @extend .w-100;
            }
        }
    }
    blockquote {
        padding: 31px 35px 30px;
        transition: all 0.3s ease-in-out;
        font-weight: 600;
        color: #333;
        margin: 30px 0 31px !important;
        background: #f6f6f6;
        text-align: center;
        font-size: 21px;
        line-height: 1.6;
    }
    .form-control {
        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        @extend .border, .w-100, .rounded, .ps-2;
        background: #f7f4f4;
        color: #232323;
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #c19d68;
        }
    }
}
.events {
    img {
        @extend .w-100, .h-100;
    }
    .event-title {
        font-size: 20px;
        line-height: 20px;
        @extend .text-ellipsis-2line;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .events-body {
        background-color: #f8f8f8;
        .entity-body {
            font-size: 15px;
            line-height: 24px;
            @extend .text-ellipsis-5line;
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
    }
}
.meeting-details {
    .title-meeting {
        font-weight: 600;
        font-family: Inter, sans-serif;
        text-transform: capitalize;
        margin: 0 0 1rem;
        color: #333369;
        line-height: 1.22em;
        font-size: 21px;
    }
    .need-help {
        background-image: url("../image/contact.jpg");
        background-size: cover;
    }
    .meeting-info {
        .pi {
            font-size: 26px;
            color: $color-main;
        }
    }
    .counter-box span {
        font-size: 58px;
        font-weight: 500;
        line-height: 1;
    }
    .img-circle {
        @extend .rounded-circle;
        width: 90px;
        height: 90px;
    }
}
.widget {
    margin-bottom: 40px;
    overflow: hidden;
    text-align: left;
    padding: 35px 25px 30px 30px;
    background: #f8f6f3;
    .widget-title {
        font-size: 24px;
        font-weight: 700;
        margin: 0 0 0;
    }
    .widget-title-detail {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 0;
    }
    ul {
        list-style: none;
        li {
            position: relative;
            border-bottom: 1px solid #f6f6f6;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            padding: 6px 0;
            a {
                @extend .w-100;
                font-weight: 500;
                transition: 0.3s;
                img {
                    width: 75px !important;
                    height: 75px !important;
                    display: inline;
                    float: left;
                    margin: 0.3em 0.75em 0.75em 0;
                }
            }
        }
    }
    .category {
        line-height: 40px;
        font-weight: 400;
        a {
            color: #686868;
        }
    }
    .btn-tag {
        color: #686868;
        display: inline-block;
        font-size: 15px !important;
        margin: 0 0px 12px 0;
        padding: 3px 9px;
        text-align: center;
        text-transform: capitalize;
        line-height: 1.4;
        border: 1px solid #ddd;
        background-color: transparent;
        &:hover {
            border: 1px solid $color-main;
            color: $color-main;
        }
    }
}
.meeting {
    .event-month {
        color: $color-main;
        text-align: right;
        font-style: italic;
        font-weight: bold;
        font-size: 14px;
    }
    .event-title {
        font-size: 36px;
        padding-bottom: 16px;
        color: $color-main;
    }
    .event-day {
        color: $color-main;
        font-weight: bold;
        text-align: center;
        font-size: 36px;
    }
    .event-description {
        color: $color-main;
        @extend .text-ellipsis-5line;
        font-size: 16px;
    }
}
.single-event {
    border-bottom: 1px solid #c3c3c4;
    padding: 60px 0;
    &:first-child {
        padding-top: 0;
    }
    .event-img {
        float: right;
        margin-left: 40px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .event-content {
        padding-left: 220px;
        position: relative;
    }
    .event-date {
        border: 1px solid;
        font-size: 16px;
        font-weight: 700;
        height: 150px;
        left: 0;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        width: 150px;
        p {
            margin: 0 0 10px;
        }
        span {
            display: block;
            font-size: 67px;
        }
    }
    .event-details > h3 {
        font-size: 24px;
        a {
            -webkit-transition: 0.3s;
            transition: 0.3s;
            color: #212324;
        }
    }
    .event-meta {
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        &::after {
            background: #c1924c none repeat scroll 0 0;
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            width: 50px;
        }
    }
    .event-details-img {
        margin-bottom: 40px;
        img {
            width: 100%;
        }
    }
    .event-details-list {
        margin-top: 20px;
        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        ul {
            list-style: outside none none;
            margin: 0;
            padding: 0;
            li {
                padding-bottom: 5px;
                padding-left: 20px;
                padding-top: 5px;
                position: relative;
                &::after {
                    background: #d0963e none repeat scroll 0 0;
                    content: "";
                    height: 6px;
                    left: 0;
                    margin-top: -3px;
                    position: absolute;
                    top: 50%;
                    width: 10px;
                }
            }
        }
    }
    .event-ticket-booking {
        ul {
            border: 1px solid #e1e1e1;
            list-style: outside none none;
            margin: 0;
            padding: 0;
            li {
                border-bottom: 1px solid #e1e1e1;
                display: block;
                padding: 15px 10px;
                span {
                    float: right;
                }
            }
        }
    }
}
