/**
* Imange hover
*/
.img_hover_3 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
.img_hover_3 img {
    width: 100%;
    transition: all 500ms ease;
}
.img_hover_3:before {
    position: absolute;
    top: 0;
    left: -75%;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    opacity: 0;
}
.img_hover_3:hover:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
    opacity: 1;
}
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.overlay-anim {
    position: relative;
}

.overlay-anim:before {
    background: hsla(0, 0%, 100%, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    opacity: 1;
    z-index: 9;
    pointer-events: none;
}

.overlay-anim:hover:before {
    height: 100%;
    opacity: 0;
    transition: all 0.4s linear;
}
