body {
    @extend .m-0;
}
@keyframes slideOutDown {
    from {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}

/***
* HEADER STYLE
*/
.header {
    .nav-link {
        @extend .fs-lg, .px-3;
    }
    .nav-item.dropdown:hover .dropdown-menu {
        display: block;
        @extend .d-block;
    }
    .logo-header {
        width: 50px;
        height: 50px;
    }
    .btn-style-two {
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 0;
        line-height: 48px;
        background-color: $color-main-hover;
        padding: 0;
        width: 140px;
        text-align: center;
        margin-top: -8px;
        position: relative;
        top: 5px;
        height: 50px;
        z-index: 9;
        border: 0;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0;
            content: "";
            background-color: $bg-red-main;
            transition: 0.5s;
            border-top-right-radius: 33px;
            border-bottom-right-radius: 33px;
            z-index: -1;
        }
        &:hover {
            color: #fff;
            &:before {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}
.sticky-top {
    animation: slideOutDown 1s forwards;
    background-color: $bg-brow !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.fixed-top {
    background-color: transparent !important;
    top: 10px !important;
    .container {
        border-bottom: 1px solid #fdfae5;
        padding-bottom: 10px;
    }
}
/**
* CAROUSE
*/
.carousel {
    .carousel-inner {
        height: 95vh;
    }
}

/***
* FOOTER STYLE
*/
.footer {
    min-height: 300px;
    color: white;
    background-color: $bg-brow;
    .logo-footer {
        width: 50px;
        height: 50px;
    }
    .list-footer {
        @extend .ps-0;
        li {
            &::marker {
                content: none;
            }
        }
    }
}
.customer-feedback {
    .client-img {
        width: 150px;
        height: 150px;
        @extend .rounded-pill;
        margin: auto;
        position: relative;
        img {
            @extend .rounded-pill;
        }
    }
    .item-customer-fb {
        height: 400px;
    }
}
.sidebar .p-panelmenu .p-panelmenu-panel .p-panelmenu-header a {
    padding-left: 8px !important;
    @extend .py-1;
}
.sidebar .p-panelmenu .p-panelmenu-content .p-menuitem {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}
.p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    @extend .px-4;
}
.sidebar {
    .p-menuitem-link{
        &hover {
            background-color: $color-main-hover !important;
        }
    }
}
