.cursor-pointer {
    cursor: pointer;
}
.text-color {
    color: $text-lv1 !important;
}
.break-work {
    word-break: break-word;
}
.h-100-vh {
    height: 100vh;
}
.h-40-px {
    min-height: 40px;
}
.bg-login {
    background-image: url("../image/home/CordialHotel.png");
    background-size: cover;
    background-position: center;
    width: 38%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.half-content {
    height: 100vh;
    min-height: 700px;
}
//footer page
.page__footer {
    height: 54px;
    bottom: 0;
    @extend .border-top, .px-0, .w-100, .position-fixed;
    span {
        padding-left: 50px;
    }
}
//sidebar
.sidebar {
    &.sidebar-collapse {
        .menu-label {
            @extend .d-none;
        }
        .p-menuitem-icon {
            transform: translateX(-50%);
        }
        + .right-content {
            .page__footer {
                width: calc(100% - 50px);
            }
        }
    }
    height: 100vh !important;
    min-width: 270px;
    max-width: 270px;
    background-color: #372410;
    transition: all 0.3s ease;
    border-right: 1px solid var(--bs-border-color);
    .p-submenu-list {
        .p-menuitem {
            .p-menuitem-link {
                @extend .h-40-px;
                .p-menuitem-text {
                    @extend .fs-md;
                }
                .p-menuitem-icon {
                    font-size: 10px;
                    padding-right: 6px;
                }
            }
        }
    }
    .hide-sub-menu {
        .p-toggleable-content {
            @extend .d-none;
        }
    }
    .item-menu {
        @extend .px-2;
        .p-menuitem-text {
            @extend .text-black;
        }
    }

    .p-highlight {
        .item-menu {
            @extend .px-2;
            .p-menuitem-text {
                @extend .fw-bold;
            }
        }
    }
    .logo {
        width: 65px;
        min-height: 65px;
        background-image: url("../image/logo.png");
        background-size: cover;
        margin: 23px auto 0px auto;
    }
    .logo-caption {
        font-size: 14;
        font-weight: 700;
    }
    .logo-toggle {
        background-image: url("../image/logo.png");
        width: 80%;
        background-size: contain;
        background-repeat: no-repeat;
        margin: auto;
        height: 45px;
        background-position: center;
        margin-bottom: 5rem;
    }
    .logo-text {
        font-size: 18px;
    }

    .sidebar__toggle-btn {
        text-align: right;
    }
    .overflow-nav {
        .nav-item {
            width: 100% !important;
        }
    }
    .nav-link {
        z-index: 1;
        display: flex;
        align-items: center;
        padding: 25px 0;
        color: $white;
        .pi {
            padding: 0 15px;
        }
        &.active {
            position: relative;
            z-index: 0;
            .pi {
                color: $warning;
            }
            margin: -29px 0;
            height: 110px;
            color: $black;
            background-image: url("../image/admin/menu-item.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    .sidebar__label {
        height: 34px;
        .item-menu,
        .item-menu:hover {
            height: 34px !important;
        }
        a {
            text-decoration: none;
        }
    }
    &-collapse {
        max-width: 50px;
        min-width: 50px;
        .logo {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 30px;
            height: 30px;
        }
        .sidebar__menu {
            display: none;
        }
        .sidebar__label {
            display: none;
        }
        .pi-chevron-down,
        .pi-chevron-right {
            display: none;
        }
    }
    &__menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: $white;
        li {
            a {
                text-decoration: none;
                @extend .text-truncate, .cursor-pointer, .position-relative, .d-block;
                .menuitem-toggle-icon {
                    position: absolute;
                    right: 8px;
                }

                &.router-link-active {
                    font-weight: 700;
                }
            }
            .submenu-pad-2 {
                padding-right: 24px;
            }

            &.active-menuitem {
                .pi {
                    color: $warning;
                }

                > ul {
                    max-height: 100%;
                }
            }
            &.last-active-menuItem {
                > a {
                    color: $black;
                    background-image: url("../image/admin/menu-item.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        > li {
            white-space: nowrap;
            > a {
                padding: 1em;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                max-height: 0;
                overflow: hidden;
                li {
                    a {
                        cursor: pointer;
                        padding-top: 0.75em;
                        padding-bottom: 0.75em;
                        padding-right: 0.75em;
                        border-top: 0 none;
                    }
                }
            }
        }
    }
    &__toggle-btn {
        float: right;
        .btn {
            bottom: 0px;
            right: 0px;
            width: 47px;
            height: 47px;
            margin: 3px;
        }
    }

    .pi {
        margin: 0 10px;
    }
    .p-panelmenu {
        .p-panelmenu-panel {
            @extend .my-0, .d-flex, .flex-column;
            .p-panelmenu-header {
                @extend .d-flex, .align-items-center;
                a {
                    @extend .ps-4, .py-1, .fs-sm;
                    text-decoration: none;
                    background-color: transparent;
                    color: $white;
                    position: relative;
                    height: 100%;
                    border: none;
                    width: calc(100% - 4px);
                    margin: 0 auto;
                    border-radius: 0;
                    font-weight: normal;
                }
                .item-menu {
                    @extend .fw-bold;
                }
            }
        }
        .p-panelmenu-content {
            background-color: transparent;
            @extend .border-0, .pt-0;
            .p-menuitem {
                @extend .my-2;
                .p-menuitem-link {
                    @extend .text-white, .position-relative;
                    .p-panelmenu-icon {
                        right: 10px;
                        margin: 0;
                    }
                }
                &.menu-active-tab > .p-menuitem-link,
                &.menu-active {
                    // border-color: $menu-active-bd;
                    // background: $menu-active-bg;
                }
            }

            ul.p-submenu-list {
                padding: 0.5rem 0;
            }
            .p-submenu-list > .p-menuitem > .p-submenu-list > .p-menuitem {
                padding: 0 0 0 16px;
            }
        }
        .p-panelmenu-icon {
            order: 1;
            position: absolute;
            top: 20%;
            right: 0;
        }
    }

    .active-child {
        color: white !important;
        @extend .px-3;
        .item-menu {
            @extend .rounded;
            background: $warning;
        }
        .p-menuitem-text {
            .sub-menu {
                @extend .text-white;
            }
        }
        .pi-circle {
            &::before {
                border-radius: 100%;
                color: white;
            }
        }
    }

    .not-active-child {
        @extend .px-3;
        .p-menuitem-text {
            .sub-menu {
                @extend .text-white;
            }
        }
    }
}

.right-content {
    @extend .h-100-vh;
    @extend .flex-fill;
    @extend .overflow-hidden;
    @extend .bg-body;

    .header {
        height: 48px;
        @extend .bg-white, .border, .border-start-0;
        .header__lang {
            width: 110px !important;
            @extend .border-0, .align-items-center, .d-flex;
            height: 30px;
            .p-dropdown-label,
            .p-dropdown-trigger {
                @extend .p-0, .align-items-center, .d-flex, .h-100, .bg-body;
            }
        }
        .header-user {
            @extend .bg-body;
            height: 30px;
            margin-right: 32px;
            .p-dropdown-label,
            .p-dropdown-trigger {
                @extend .py-0, .px-2, .align-items-center, .d-flex, .h-100, .fs-md;
            }
            .p-menuitem-link {
                height: 30px;
            }
            .p-menubar-root-list {
                z-index: 1009 !important;
                @extend .mb-0;
                .p-menuitem {
                    @extend .d-flex;
                    .p-menuitem-link {
                        display: contents;
                        .p-menuitem-text,
                        .p-submenu-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @extend .px-2;
                        }

                        .p-submenu-icon {
                            @extend .d-none;
                        }
                    }
                    .p-submenu-list {
                        @extend .border-0;
                        right: 0;
                        top: 35px;
                        min-width: 250px;
                        background-color: var(--bs-white);
                        max-width: 205px;
                        border-radius: 5px;
                        height: auto;
                        padding: 0;
                        overflow: hidden;
                        .p-menuitem {
                            min-height: 32px;
                            @extend .py-2, .px-3;
                            .p-menuitem-content {
                                min-height: 28px;
                                width: 100%;
                            }
                            &:hover {
                                background-color: #372410;
                                .p-menuitem-content {
                                    background-color: #372410;
                                    .p-menuitem-text {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .toggle {
            height: 45px;
            width: 48px;
            label {
                @extend .justify-content-center, .align-items-center, .d-flex;
                width: 54px !important;
            }
        }
        .p-dropdown {
            .p-dropdown-label {
                min-height: 28px !important;
            }
        }
        .item-user {
            .p-menuitem-text {
                @extend .w-100;
                justify-content: left !important;
            }
        }
    }

    .page__footer {
        width: calc(100% - 270px);
        height: 54px;
        padding-right: 1.5em;
        position: fixed;
        bottom: 0;
    }
}

.count-label {
    color: black;
    width: 26px;
    height: 18px;
    text-align: center;
    line-height: initial;
    font-size: 14px;
    @extend .rounded, .px-2;
}
.p-menubar-root-list {
    margin-bottom: 0px !important;
    .p-menuitem {
        .p-menuitem-link {
            .p-submenu-icon {
                margin-top: 8px !important;
            }
        }
    }
}
.page-not-found {
    height: 70%;
    align-items: center;
    display: flex;
    justify-content: center;
    h1 {
        font-size: 50px;
    }
}
.with-sidebar {
    .header {
        height: 48px;
        @extend .border, .border-start-0;
        border-color: $primary !important;
        .header-user {
            height: 30px;
            margin-right: 10px;
            .p-dropdown-label,
            .p-dropdown-trigger {
                @extend .py-0, .px-2, .align-items-center, .d-flex, .h-100, .fs-md;
            }
            .user {
                // background-image: url("../images/user-without-sidebar.svg");
            }
            .p-menuitem-link {
                height: 30px;
            }
            .p-menubar-root-list {
                .p-menuitem {
                    @extend .d-flex;
                    .p-menuitem-link {
                        display: contents;
                        .p-menuitem-text,
                        .p-submenu-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @extend .text-white, .px-2;
                        }
                    }
                    .p-submenu-list {
                        @extend .border-0;
                        right: 0;
                        top: 35px;
                        min-width: 250px;
                        background-color: var(--bs-white);
                        max-width: 205px;
                        border-radius: 5px;
                        height: auto;
                        padding: 0;
                        overflow: hidden;
                        .p-menuitem {
                            min-height: 32px;
                            @extend .py-2, .px-3;
                            .p-menuitem-link {
                                display: contents;
                            }
                        }
                    }
                }
            }
        }
        .p-dropdown {
            .p-dropdown-label {
                min-height: 28px !important;
            }
        }
        .item-user {
            .p-menuitem-text {
                @extend .w-100;
                justify-content: left !important;
            }
        }
    }
}
.init-tooltip {
    visibility: visible !important;

    &.file-tooltip,
    &.group-tooltip,
    &.input-tooltip {
        .p-tooltip-arrow {
            border-bottom: 3px solid $primary;
        }

        .p-tooltip-text {
            @extend .fs-md, .px-2, .shadow;
            background: var(--bs-white);
            border-top: 3px solid $primary;
        }
    }

    &.chip-config-tooltip {
        .p-tooltip-arrow {
            border-right: 3px solid $primary;
        }
        .p-tooltip-text {
            max-width: 500px;
            @extend .fs-md, .px-2, .shadow;
            background: var(--bs-white);
        }
    }
    &.file-tooltip,
    &.input-tooltip.right-tooltip {
        transform: translateX(24px);

        .p-tooltip-arrow {
            left: calc(100% - 33px) !important;
        }
    }

    &.file-tooltip {
        max-width: 580px;

        .p-tooltip-text {
            padding: 24px;

            .file-tooltip-container {
                @extend .d-flex, .flex-column;

                & > *:last-child {
                    @extend .mb-0;
                }

                ul {
                    @extend .d-flex, .flex-column;
                    list-style: circle;
                    gap: 10px;
                }

                table {
                    @extend .table, .table-striped, .m-0;

                    tr:first-child {
                        background-color: $color-main-hover;

                        th {
                            border-color: $color-main-hover;
                        }

                        th:first-child {
                            border-top-left-radius: var(--bs-border-radius);
                        }

                        th:last-child {
                            border-top-right-radius: var(--bs-border-radius);
                        }

                        th:nth-child(2),
                        th:last-child {
                            @extend .position-relative;

                            span:first-child {
                                @extend .d-inline-block, .position-absolute;
                                top: 20%;
                                left: 0;
                                width: 1px;
                                height: 60%;
                                background-color: #b8bbbe;
                            }
                        }
                    }

                    tr:nth-child(2) {
                        height: 3px;

                        td {
                            @extend .p-0;
                        }
                    }

                    th:first-child,
                    td:first-child {
                        @extend .text-center;
                    }
                }
            }
        }
    }

    &.group-tooltip {
        max-width: 400px;
        transform: translateX(calc(-50% + 10px));
    }

    &.input-tooltip.left-tooltip {
        transform: translateX(calc(50% - 8px));
    }

    &.value-tooltip {
        max-width: 420px;
        .p-tooltip-arrow {
            display: none;
        }

        .p-tooltip-text {
            @extend .fs-md, .shadow;
            background: $color-main-hover;
            border: 1px solid var(--bs-white);
        }
    }
    .p-tooltip-text {
        color: #372410;
    }
}
// data table
.p-table-common {
    &.p-datatable {
        .p-datatable-thead {
            @extend .h-40-px;
            .p-sortable-column {
                span {
                    svg {
                        background-repeat: no-repeat;
                        background-image: url("../image/admin/sort.svg");
                        background-position: center;
                        &:before {
                            content: none;
                        }
                    }
                }
                &[aria-sort="descending"] {
                    .p-column-header-content {
                        span {
                            svg {
                                background-image: url("../image/admin/arrow-down.svg");
                                background-position: center center;
                                position: relative;
                                top: -3px;
                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
                &[aria-sort="ascending"] {
                    .p-column-header-content {
                        span {
                            svg {
                                background-image: url("../image/admin/arrow-up.svg");
                                background-position: center center;
                                position: relative;
                                top: -3px;
                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
            tr {
                @extend .h-100;
                th {
                    border-right: 1px solid $bd-header-table !important;
                    &:first-child {
                        border-top-left-radius: 4px;
                    }
                    &:last-child {
                        border-top-right-radius: 4px;
                        @extend .border-end-0;
                    }
                    background-color: $bg-header-table;
                    .p-column-header-content {
                        .p-column-title {
                            @extend .ps-2;
                        }
                        .p-column-header-content {
                            @extend .w-100, .border-end, .h-100;
                            .p-column-title {
                                @extend .text-color, .fw-bold;
                            }
                        }
                        .p-sortable-column-icon {
                            width: 15px;
                            height: 15px;
                            padding: 10px 0;
                            margin-left: 5px;
                            background-repeat: no-repeat;
                        }
                    }
                    &:last-child {
                        .p-column-header-content {
                            @extend .border-0;
                        }
                    }
                }
            }
        }
        .p-datatable-wrapper {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            overflow-y: hidden !important;
            @extend .h-100;
        }
        .p-datatable-tbody {
            display: table-caption;
            margin-top: 2px;
            overflow-y: auto;
            height: calc(100vh - 215px);
            tr {
                display: flex !important;
                &:first-child {
                    @extend .border-top;
                }
                @extend .border-bottom;
                background-color: $white !important;
                td {
                    @extend .break-work, .align-items-center, .d-flex, .py-2;
                    min-height: 38px;
                }
                &.p-row-odd {
                    background-color: $bg-row-unselect !important;
                }
                &:last-child {
                    @extend .border-bottom-0;
                }
                &:hover {
                    background-color: $hv-table !important;
                }
                &.p-highlight {
                    background-color: $selected-table !important;
                }
            }
        }
        .p-datatable-emptymessage {
            @extend .border-top;
            td {
                @extend .px-3;
            }
        }
    }
}
.toast-icon-size {
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.p-toast-bottom-right {
    right: 65px;
}
.p-toast {
    .p-toast-message {
        &.p-toast-message-success {
            .p-toast-message-icon {
                min-width: 32px;
                height: 32px;
                margin-top: 20px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("../image/admin/checked.svg");
                path {
                    display: contents;
                }
            }
        }
        &.p-toast-message-error {
            .p-toast-message-icon {
                min-width: 32px;
                height: 32px;
                margin-top: 20px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("../image/admin/error.svg");
                path {
                    display: contents;
                }
            }
        }
        &.p-toast-message-info {
            .p-toast-message-icon {
                min-width: 32px;
                height: 32px;
                margin-top: 20px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("../image/admin/information.svg");
                path {
                    display: contents;
                }
            }
        }
        background-color: $white !important;
        @extend .py-2, .px-3;
        border-radius: 0.25rem;
        .p-toast-message-content {
            .p-toast-icon-close-icon.pi {
                &.pi-times {
                    &:before {
                        display: none;
                    }
                    min-width: 25px;
                    min-height: 25px;
                    display: inline-block;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin: auto;
                    background-image: url("../image/admin/close.svg");
                }
            }
            .p-toast-message-icon.pi {
                min-width: 32px;
                height: 32px;
                margin-top: 20px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                &.pi-info-circle {
                    &:before {
                        display: none;
                    }
                    background-image: url("../image/admin/information.svg");
                }
                &.pi-exclamation-triangle {
                    &:before {
                        display: none;
                    }
                    background-image: url("../image/admin/warning.svg");
                }
                &.pi-times {
                    &:before {
                        display: none;
                    }
                    background-image: url("../image/admin/error.svg");
                }
                &.pi-check {
                    &:before {
                        display: none;
                    }
                    background-image: url("../image/admin/checked.svg");
                }
            }
            .p-toast-message-text {
                margin-left: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
                .p-toast-summary {
                    color: $black;
                    padding: 0px;
                    @extend .fw-bold;
                }
                .p-toast-detail {
                    color: $black;
                }
            }
        }
    }
}
.p-menuitem-content {
    @extend .d-flex;
}
div.dialog-img {
    width: 100%;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    &.checkmark {
        background-image: url("../image/admin/checkmark.svg");
    }

    &.unlink {
        background-image: url("../image/admin/unlink.svg");
    }
    // popup when add, delete, edit
    &.delete-message {
        height: 70px !important;
        margin-top: 20px;
        display: inline-block;
        background-size: contain;
        background-image: url("../image/admin/delete-modal.svg");
    }
    &.error-message {
        height: 70px !important;
        margin-top: 20px;
        display: inline-block;
        background-size: contain;
        background-image: url("../image/admin/error.svg");
    }
    &.warning-message {
        height: 88px !important;
        margin-top: 20px;
        display: inline-block;
        background-size: contain;
        background-image: url("../image/admin/confirmation.svg");
    }
}
.h-img-blog {
    height: 300px;
    .p-image,
    img {
        @extend .h-100, .w-100;
    }
}
.h-body-blog {
    height: 210px;
    .entity-body {
        height: 72px !important;
    }
}
.is-active {
    background-color: #c19d68;
}