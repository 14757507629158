$bg-brow: #222;
$bg-red-main: #da3c3c;
$bg-white-brown: #d2d2e2;
$bg-blue-main: #0e2a4e;
$color-text-placeholder: #a3a3a3;
$color-main: #372410;
$color-main-hover: #c19d68;
$color-main-shadow: rgb(190 169 122 / 26%);

$theme-color1: #aa8453;
$bg-home: #f8f5f0;
//base color
$blue: #08107b; //same with primary
//border
$border-color: #d5d5d5;
//component
$component-active-bg: $border-color;
//sidebar
$bg-sidebar-active: #eeeeee;
$bg-sidebar: #f6f6f6;
$white: #fff;
 
/* Menu Common */
$menuitemActiveRouteColor: $blue;
 
/* Menu Light */
$menuBgColor: $bg-sidebar;
$menuitemColor: #232428;
$menuitemBgColor: $bg-sidebar-active;
$menuitemActiveColor: $blue;
$menuitemActiveBgColor: $white;
$menuitemBodyActiveBgColor: $menuitemBgColor;
$menuitemBorderColor: $border-color;
$bg-footer: #ededed;
$panel-color: #707070;
$warning: #e9b226;
$black: #000;
$btn-yellow: #eab227;
$text-icon: #212529;
$bg-main: #f3f3f3;
$menu-icon: #9c9c9c;
$menu-active-bd: #dee2e6;
$menu-active-bg: #e9ecef;
$bt-toggle: #f9f9f9;
$primary: #08107b; // primary
$status-footer: #d65d68;
$text-lv1: #444444;
$text-lv2: #333333;
$text-lv3: #666666;
$text-lv4: #999999;
$text-lv5: #777777;
$text-lv6: #8d8d8d;
/* Header */
$headerMinHeight: 62px;
/* Footer */
$footerHeight: 40px;
/* duration effect */
$duration: 0.4s;
/* background btn hover */
$panel-language: #0000001a;
$bd-header-table: #cdcee3;
$title-modal: #5257a2;
$bg-secondary: #dddeed;
$chevron: #393939;
$selected-table: #eef2ff;
$bg-row-unselect: #fbfbfb;
$hv-table: #cfd1e9;
$bg-pagination: #f6f8fa;
$paginator-end: #dfe3eb;
$placeholder: #a2a2a2;
$bg-dropdown: #f1f1f1;
$text-input-color: #aaaaaa;
$primary-hv: #436ed2;
$btn-warning: #e9b226;
$btn-hv-warning: #ffcf55;
$bg-header-table: #e7e8f2;
$text-secondary: #b0b0b0;
$bg-hover-multi-select: #dadcef;
$progressBgColor: var(--bs-primary);
$bg-p-tabview: #e7e8f2;
$text-title: #333;
$brown-color: #e4e4e4;
$menu-text: #b3b3b3;
$border-multi-select: #949494;
$bg-panel: #f2f4f9;
$bg-table: #eeeeee;
$bg-table-row-hover: #f2f2f2;
$bg-table-border-color: #e2e2e2;
 
/* Text */
$text-title-color: #3d3d3d;
 
/* Icon */
$icon-lv1: #ababab;
$bg-gray: #cfcfcf;
$status-connect: #436ed2;
$status-part: #eab227;
$bg-close-panel: #dfe0ed;
$text-color: #212529;