.container-rooms {
    max-width: 1600px;
}
.height-400 {
    height: 400px;
}
.room-block-two {
    .inner-box {
        @extend .border, .mx-3;
    }
    .p-image-preview-container,
    image {
        width: 100%;
        height: 100%;
    }
    .image {
        @extend .position-relative, .pb-3, .overflow-hidden;
        img {
            transition: 0.5s;
        }
    }
    img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .price {
        letter-spacing: 1px;
        font-weight: 400;
        bottom: 25px;
        width: 85%;
        @extend .mb-0, .bg-white, .rounded, .position-absolute, .py-2;
    }
    h3 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 20px;
        a {
            color: $color-main;
            transition: 0.5s;
            &:hover {
                color: $color-main-hover;
            }
        }
    }
    a {
        text-decoration: none;
        cursor: pointer;
    }
    .icon-list {
        line-height: 32px;
    }
    .text-two {
        @extend .fs-md, .mb-3;
        line-height: 28px;
        color: #797979;
        font-weight: 400;
    }
    .sale-off {
        @extend .pt-1,
            .d-flex,
            .position-absolute,
            .rounded-pill,
            .align-items-center,
            .text-white,
            .justify-content-center;
        left: 20px;
        top: 10px;
        background: #ffac41;
        height: 50px;
        width: 50px;
    }
}
.price-original {
    @extend .mb-0, .px-3;
    text-decoration: line-through;
    font-size: 14px;
}
.price-sale {
    @extend .mb-0, .px-3, .fw-bold;
    font-size: 16px;
    color: #da3c3c;
}
.rooms {
    .p-inputnumber-input {
        width: 80px;
    }
    .p-galleria-thumbnail-container {
        background-color: transparent;
    }
    .p-galleria {
        .p-galleria-thumbnail-prev,
        .p-galleria-thumbnail-next {
            @extend .d-none;
        }
        .p-galleria-item {
            img {
                @extend .w-100, .rounded;
                height: 520px;
            }
        }
        .p-galleria-thumbnail-item {
            @extend .my-3;
            .p-galleria-thumbnail-item-content {
                @extend .w-100, .overflow-hidden, .mx-2;
                border-radius: 12px !important;
                height: 160px;
                img {
                    @extend .w-100, .h-100;
                }
            }
        }
    }
    .room-content {
        .pricing {
            font-size: 20px;
            letter-spacing: 3px;
            line-height: 35px;
            color: #da3c3c;
            font-weight: 700;
        }
        .sec-title {
            font-size: 34px;
            line-height: 60px;
            color: #0e2a4e;
            font-weight: 800;
            margin-bottom: 50px;
            position: relative;
        }
        .text {
            font-size: 18px;
            line-height: 28px;
            color: #797979;
            font-weight: 400;
        }
        .check-availability {
            position: relative;
            z-index: 99;
            .style-seven {
                background: #f1f3f6;
                padding: 55px 40px 70px;
            }
        }
    }
    .sec-title {
        font-size: 36px;
        line-height: 60px;
        color: #0e2a4e;
        font-weight: 800;
        margin-bottom: 16px;
        position: relative;
    }
}
.room-details {
    margin-top: 20px;
    .pricing {
        font-size: 20px;
        letter-spacing: 3px;
        line-height: 35px;
        color: #da3c3c;
        font-weight: 700;
    }
    .room-title {
        font-size: 18px;
        line-height: 28px;
        color: #797979;
        font-weight: 400;
    }
    h4 {
        font-size: 28px;
        padding-top: 32px;
        line-height: 60px;
        color: #0e2a4e;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .amenities {
        font-size: 16px;
        color: #575757;
        font-weight: 400;
        padding: 0px;
    }
    .check {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 47px;
        color: #da3c3c;
        font-weight: 700;
        text-transform: uppercase;
    }
    .style-seven {
        background: #f1f3f6;
        padding: 55px 40px 70px;
    }
    li {
        @extend .fs-md;
        line-height: 24px;
    }
}
.w-slider {
    width: 365px;
    .p-inputnumber-input {
        width: 50px;
        height: 50px;
        box-shadow: none;
        pointer-events: none;
        @extend .rounded-circle, .border-0, .text-center;
    }
    .p-inputnumber-button {
        @extend .rounded-circle, .border, .mt-1;
        background-color: transparent;
        color: black;
        width: 40px;
        height: 40px;
    }
}
.mh-56 {
    min-height: 56px;
}
.w-content {
    overflow: auto !important;
}
.delete-img {
    @extend .pt-1,
        .d-flex,
        .position-absolute,
        .rounded-pill,
        .align-items-center,
        .text-white,
        .justify-content-center;
    margin-right: 20px;
    margin-top: -70px;
    height: 50px;
    width: 50px;
}
