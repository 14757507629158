.auto-container {
    position: static;
    max-width: 1400px;
    padding: 0px 15px;
    margin: 0 auto;
    .service-title {
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 47px;
        color: #da3c3c;
        font-weight: 700;
        text-transform: uppercase;
    }
    .text {
        font-size: 24px;
        color: #0e2a4e;
        margin-bottom: 70px;
    }
    .service-block-three {
        .image {
            margin-right: -25px;
            overflow: hidden;
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
            img {
                transition: 0.5s;
            }
        }
        img {
            display: inline-block;
            max-width: 100%;
            height: auto;
        }
        .content {
            background: #f1f3f5;
            padding: 50px;
            margin-left: -160px;
            margin-top: 70px;
            position: relative;
            z-index: 5;
            margin-bottom: 115px;
            min-height: 585px;
            &:before {
                position: absolute;
                content: "";
                top: -12px;
                right: 0px;
                bottom: 0px;
                left: -12px;
                background-color: rgb(255 255 255 / 28%);
                z-index: -1;
            }
            .sub-title {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 1.6em;
                color: #da3c3c;
                font-weight: 700;
                text-transform: uppercase;
                padding-left: 53px;
                padding-right: 53px;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 10px;
                &:before {
                    position: absolute;
                    content: "";
                    height: 1px;
                    width: 45px;
                    background: #da3c3c;
                    left: 0;
                    bottom: 7px;
                }
            }
        }
        .sec-title.small {
            font-size: 45px;
        }
        .text-two {
            font-size: 18px;
            line-height: 28px;
            color: #797979;
            font-weight: 400;
        }
        .time {
            font-size: 20px;
            line-height: 30px;
            color: #da3c3c;
            font-weight: 400;
            margin-top: 35px;
        }
        .link-btn {
            position: absolute;
            right: -21px;
            bottom: -20px;
            padding: 20px 20px;
        }
        .btn-style-two {
            position: relative;
            display: inline-block;
            padding: 15px 40px;
            overflow: hidden;
            vertical-align: middle;
            transition: 0.5s;
            text-decoration: none;
            z-index: 1;
            border-radius: 4px;
            background-color: #fff;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 28px;
            color: #000000;
            font-weight: 700;
            text-transform: uppercase;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0;
                content: "";
                background-color: $bg-red-main;
                transition: 0.5s;
                border-top-right-radius: 33px;
                border-bottom-right-radius: 33px;
                z-index: -1;
            }
            &:hover {
                color: #fff;
                &:before {
                    width: 100%;
                    border-radius: 0;
                }
            }
        }
        .left-content {
            .image-column {
                order: 2;
            }
            .content {
                margin-left: 0;
                margin-right: -160px;
            }
            .link-btn {
                @extend .px-0;
                left: 0;
            }
        }
    }
}