@media only screen and (min-width: 768px) {
    .gallery {
        .gallery-list {
            height: 450px;
            .p-image {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .gallery-height {
            height: 241px;
            .p-image {
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .switch-left {
            .img-big {
                order: 2;
            }
            .img-left,
            .img-ge {
                height: 100%;
                margin-left: 4px;
            }
            .img-right,
            .img-group,
            .img-gs {
                height: 100%;
                margin-right: 4px;
            }
            .img-bottom {
                height: 100%;
                margin-top: 8px;
            }
        }
        .switch-right {
            .img-left,
            .img-ge {
                height: 100%;
                margin-left: 4px;
            }
            .img-gs,
            .img-right {
                height: 100%;
                margin-right: 4px;
            }
            .img-bottom {
                height: 100%;
                margin-top: 8px;
            }
        }
        .image-about {
            margin-left: 4px;
        }
    }
}
.delete-gallery {
    position: relative;
    z-index: 1051;
    bottom: 55px;
    right: -20px;
}
.item-carousel {
    height: 300px;
    width: 100%;
    .p-image-preview-container {
        height: 100%;
        width: 100%;
    }
}
