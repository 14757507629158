/*
    WARNING: attribute not working on IE:
        fit-content
*/
.font-jp {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}
.mh-50 {
    max-height: 50%;
}
.mh-50-vh {
    max-height: 50vh;
}
.mh-70-vh {
    max-height: 70vh;
}
.mw-none {
    max-width: none !important;
}
.mw-120px {
    min-width: 120px !important;
}

.w-120px {
    width: 120px !important;
}

.mw-155px {
    min-width: 155px !important;
}

.mw-72px {
    min-width: 72px !important;
}
.w-5 {
    width: 5%;
}
.w-10 {
    width: 10%;
}
.w-15 {
    width: 15%;
}
.w-45 {
    width: 45%;
}
.w-40 {
    width: 40%;
}
.w-95 {
    width: 95%;
}
.w-90 {
    width: 90%;
}
.w-35 {
    width: 35%;
}
.w-55 {
    width: 55%;
}
.w-85 {
    width: 85%;
}
.w-70 {
    width: 70%;
}
.w-60 {
    width: 60%;
}
.w-65 {
    width: 65%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.mm-50 {
    max-width: 50%;
}
.w-40-px {
    width: 40px !important;
}
.w-64-px {
    width: 64px;
}
.w-80-px {
    width: 80px;
}
.w-85-px {
    width: 85px;
}
.w-100-px {
    width: 100px;
}
.w-105-px {
    width: 105px;
}
.h-80 {
    height: 80%;
}
.h-70-vh {
    height: 70vh;
}
.w-92-px {
    width: 92px;
}
.w-112-px {
    width: 112px;
}
.w-125-px {
    width: 125px;
}
.min-h-scrollbar-content {
    min-height: calc(100% - 9px);
}
.min-w-400 {
    min-width: 400px;
}
.min-w-280 {
    min-width: 280px;
}
.h-100-vh {
    height: 100vh !important;
}
.h-45-px {
    height: 45px;
}
.h-40-px {
    height: 40px;
}
.h-35 {
    height: 35%;
}
.h-65 {
    height: 65%;
}
.cursor-pointer {
    cursor: pointer;
}
.border-radius-25 {
    border-radius: 0.25rem;
}
.ms-22 {
    margin-left: 22px;
}
.btn-w-100 {
    width: 100px !important;
}
.btn-w-110 {
    min-width: 110px;
}
.btn-w-120 {
    min-width: 120px;
}
.btn-w-140 {
    min-width: 140px;
}
.btn-w-160 {
    min-width: 160px;
}
.btn-w-180 {
    min-width: 180px;
}
.btn-w-200 {
    min-width: 200px;
}
// font size text
.fs-xs {
    font-size: 12px;
}
.fs-sm {
    font-size: 13px;
}
.fs-md {
    font-size: 14px !important;
}
.fs-lg {
    font-size: 15px !important;
}
.fs-lgg {
    font-size: 17px !important;
}
.fs-xl {
    font-size: 16px;
}
.fs-xxl {
    font-size: 18px;
}
.fs-xxxl {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.btn-w-260 {
    min-width: 260px;
}
.bg-white-50 {
    background-color: #fbfbfb;
}
.bg-black {
    background-color: $black;
}
.bg-silver {
    background-color: #c0c0c0ff;
}
.bg-sky-blue {
    background-color: #87ceebff;
}
.txt-white {
    color: $white;
}
.txt-black {
    color: $black;
}
.border-y {
    @extend .border-top;
    @extend .border-bottom;
}
.border-x {
    @extend .border-start;
    @extend .border-end;
}
.white-space-pre {
    white-space: pre !important;
}
.text-ellipsis {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
.z-index-13 {
    z-index: 1300 !important;
}
/* Chrome, Safari, Edge, Opera */
input.hide-arrow::-webkit-outer-spin-button,
input.hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type="number"].hide-arrow {
    -moz-appearance: textfield;
}
.fs-title-page {
    font-size: 54px;
}
.fs-title-header {
    font-size: 24px;
}
.fs-title-table {
    font-size: 20px;
}
.text-color {
    color: $text-lv1 !important;
}
.fill-height {
    flex: 1 1 auto;
    min-height: 10vh;
}
.w-max-content {
    width: max-content;
    width: -moz-max-content;
}
.centered-control {
    @extend .align-items-center, .d-flex, .justify-content-center;
}
//color common
.bg-page-main {
    background-color: $bg-main;
}
.min-width-200 {
    min-width: 200px !important;
}
.h-normal {
    height: 32px;
}
.break-work {
    word-break: break-word;
}
.bg-button-secondary {
    background-color: $bg-secondary;
}
.bg-page {
    background-color: #f3f3f3;
}
.table-common {
    .p-datatable-wrapper {
        border-radius: 4px !important;
    }
}
.height-sm {
    height: 38px !important;
}
.mw-5 {
    width: 5%;
}
.mw-7-5 {
    width: 7.5%;
}
.mw-10 {
    width: 10%;
}
.mw-15 {
    width: 15%;
}
.mw-35 {
    width: 35%;
}
.mw-45 {
    width: 45%;
}
.mw-125 {
    width: 12.5%;
}
.mw-225 {
    width: 22.5%;
}
.mw-275 {
    width: 27.5%;
}
.mw-25 {
    width: 25%;
}
.mw-525 {
    width: 52.5%;
}
.mw-175 {
    width: 17.5%;
}
.mh-50px {
    height: 50px;
}
.mw-20 {
    width: 20%;
}
.mw-30 {
    width: 30%;
}
.mw-60 {
    width: 60%;
}
.px-20px {
    padding-left: 24px;
    padding-right: 20px;
}
.h-32px {
    height: 32px !important;
}
.break-spaces {
    white-space: "break-spaces";
}
.min-h-40px {
    height: initial !important;
    min-height: 40px;
}
.text-failed {
    color: #d80f30;
}
.text-pause {
    color: #e9b226;
}
.text-title {
    color: $text-title !important;
}
.btn-size-md {
    min-width: 120px;
}
.h-40px {
    height: 40px;
}
.bg-custom-secondary {
    background-color: $bg-secondary;
}
body {
    background-color: #fdfcf6;
    color: #686868;
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.lora-hotel {
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
a {
    @extend .text-decoration-none;
}
.fs-md {
    font-size: 14px;
}
.fs-lg {
    font-size: 16px;
}
.fs-xl {
    font-size: 18px;
}
.fs-xxl {
    font-size: 20px !important;
}
.icon-sea {
    font-size: 50px !important;
    width: 1em;
    height: 1em;
}

.text-ellipsis-5line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-ellipsis-1line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-ellipsis-2line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-ellipsis-3line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3 !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
a {
    color: $color-main;
    &:hover {
        color: $color-main-hover;
    }
}
.btn-action {
    @extend .position-relative,
        .d-inline-block,
        .overflow-hidden,
        .text-decoration-none,
        .rounded,
        .text-uppercase,
        .p-3;
    vertical-align: middle;
    transition: 0.5s;
    z-index: 1;
    background-color: #372410;
    font-size: 14px;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 700;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        content: "";
        background-color: #c19d68;
        transition: 0.5s;
        border-top-right-radius: 33px;
        border-bottom-right-radius: 33px;
        z-index: -1;
    }
    &:hover {
        @extend .text-white;
        &:before {
            @extend .w-100, .rounded;
        }
    }
}
.btn-social {
    height: 40px;
    width: 40px;
    @extend .text-center, .mx-2, .rounded-pill;
    color: #adadad;
    border: 1px solid #adadad;
    display: inline-block;
    &:hover {
        background-color: #aa8453;
        border-color: transparent;
        color: #fff;
    }
}
.bnt-blog-category {
    display: inline-block;
    font-size: 14px;
    @extend .d-inline-block, .text-uppercase, .text-white, .border-0;
    font-weight: 500;
    background: $color-main;
    padding: 6px 25px;
    &:hover {
        background-color: $color-main-hover;
        border-color: transparent;
        color: #fff;
    }
}
input {
    min-height: 40px;
}
.btn-custom-inline {
    position: absolute;
    height: 100%;
    @extend .border-0;
    top: 3px;
    right: 0px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
button {
    &:disabled {
        @extend .border-0;
    }
}
textarea {
    min-height: 150px !important;
}
.p-multiselect-label {
    height: 40px;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $color-main;
    background: $color-main;
}
.p-multiselect-panel {
    .p-multiselect-items {
        @extend .mb-0, .pt-0, .px-0;
        .p-multiselect-item {
            &.p-highlight {
                background-color: transparent;
            }
        }
    }
    .p-multiselect-header {
        background-color: transparent;
    }
    span {
        @extend .fs-md;
    }
}

.btn-action-sm {
    @extend .position-relative, .d-inline-block, .overflow-hidden, .text-decoration-none, .rounded, .p-2;
    vertical-align: middle;
    max-width: 200px;
    min-width: 80px;
    transition: 0.5s;
    z-index: 1;
    background-color: #372410;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 28px;
    color: #fff;
    font-weight: 700;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        content: "";
        background-color: #c19d68;
        transition: 0.5s;
        border-top-right-radius: 33px;
        border-bottom-right-radius: 33px;
        z-index: -1;
    }
    &:hover {
        @extend .text-white;
        &:before {
            @extend .w-100, .rounded;
        }
    }
}
.item-select {
    &:last-child {
        .character-s {
            @extend .d-none;
        }
    }
}
.p-panelmenu-header {
    @extend .px-3;
}
.p-panelmenu-header-content {
    @extend .w-100;
    background-color: transparent !important;
    border: none !important;
}
.w-80px {
    width: 120px !important;
}
.btn-cancel-sm {
    @extend .position-relative, .d-inline-block, .overflow-hidden, .text-decoration-none, .rounded, .p-2;
    vertical-align: middle;
    max-width: 160px;
    min-width: 80px;
    transition: 0.5s;
    z-index: 1;
    background-color: $color-main-hover;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 28px;
    color: #fff;
    font-weight: 700;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        content: "";
        background-color: $color-main;
        transition: 0.5s;
        border-top-right-radius: 33px;
        border-bottom-right-radius: 33px;
        z-index: -1;
    }
    &:hover {
        @extend .text-white;
        &:before {
            @extend .w-100, .rounded;
        }
    }
}
.h-60px {
    height: 60px;
}
.p-tabview-nav {
    @extend .ps-0, .mb-0, .pt-3;
    .p-tabview-selected {
        .p-tabview-nav-link {
            color: #dba765;
            background: #dddddd;
            @extend .fw-bold;
            border-color: #dba765;
        }
    }
    .p-tabview-nav-link {
        @extend .rounded-0, .justify-content-center;
        color: black;
        min-width: 170px;
        font-family: Sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 22px;
        text-decoration: none;
    }
}

.search {
    input[type="text"] {
        width: 100%;
        display: block;
        height: 58px;
        background-color: #fff;
        border: 1px solid #e7e7e7;
        padding-left: 30px;
        padding-right: 30px;
        color: #090909;
        outline: none;
        border-radius: 4px;
    }
    .icons {
        background: transparent none repeat scroll 0 0;
        border: 0 none;
        color: #686868;
        font-size: 18px;
        padding: 1px 15px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 53px;
    }
}
ul {
    &.list {
        list-style: none;
    }
}
.bd-dashed {
    border-bottom: 1px dashed #7070702b;
}
.text-main {
    color: $color-main;
}
.bg-main {
    background-color: $color-main;
}
.bg-color-hover {
    background-color: $color-main-hover;
}
.dropdown-menu {
    min-width: 220px;
    @extend .py-0;
    background-color: white;
    .dropdown-item {
        color: $color-main;
        @extend .py-2, .rounded-pill;
        &:hover,
        &.active {
            background-color: $color-main-hover;
            color: white;
        }
    }
}

.h-carousel {
    height: 350px !important;
}
.p-dropdown {
    min-height: 40px;
    align-items: center;
}
.p-dropdown-items {
    @extend .ps-0, .mb-0, .fs-md;
    li {
        height: 40px;
        &:hover {
            @extend .border-0, .text-white;
            background-color: $color-main-hover;
        }
    }
}
.p-dropdown-label {
    @extend .fs-md;
}
.word-wrap {
    word-wrap: break-word;
    word-break: break-all;
}
.shadow-2 {
    box-shadow:
        0 4px 10px rgba(0, 0, 0, 0.03),
        0 0 2px rgba(0, 0, 0, 0.06),
        0 2px 6px rgba(0, 0, 0, 0.12) !important;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $color-main;
    background: $color-main;
}
$bg-datepicker: #bebebe;

.p-calendar-input {
    cursor: pointer;
    min-height: 38px;
}
.calendar-btn {
    visibility: inherit !important;
}

.p-calendar {
    @extend .border, .rounded;
    height: 42px;
    .p-calendar-input,
    .p-button-icon-only {
        @extend .border-0, .p-0, .height-sm;
    }
    .p-datepicker-buttonbar {
        @extend .border-top, .py-2, .mt-3;
    }
    .p-inputtext {
        height: 38px !important;
        @extend .border-0;
        box-shadow: none;
        &:hover {
            background-color: transparent;
        }
    }
    .p-calendar-input,
    .p-button-icon-only {
        @extend .border-0, .py-0, .bg-white, .rounded, .px-2, .height-sm;
    }
    &.p-calendar-w-btn {
        .p-calendar-button {
            @extend .border-0, .height-sm, .bg-white;
            top: 4px;
            right: 0;
            width: 38px;

            span {
                top: 6px;
                left: 6px;
                margin: 0;
            }
        }
    }
}
.p-datepicker {
    min-width: 424px !important;
    @extend .border, .rounded, .bg-white;

    .p-datepicker-buttonbar {
        @extend .border-top;
        padding: 18px 32px 30px;
        margin-top: 20px;
    }

    .p-datepicker-title {
        @extend .text-primary, .fw-bold;
        .p-datepicker-month {
            @extend .px-2, .fw-bold;
            color: $color-main;
        }
        button {
            @extend .fs-xxl;
        }
        .p-datepicker-year {
            @extend .fw-bold;
            color: $color-main;
        }
    }

    .p-datepicker-calendar-container {
        @extend .px-3;
    }

    .p-datepicker-header {
        padding: 32px 32px 26px;
        .p-datepicker-prev,
        .p-datepicker-next {
            @extend .p-0, .border-0, .bg-white;
        }
    }

    .p-datepicker-calendar {
        thead {
            @extend .text-center;
            tr {
                span {
                    color: $color-main !important;
                }
            }
        }

        span {
            @extend .rounded, .text-color, .text-center, .fs-lg;
            margin: auto;
            padding: 8px 0;
            width: 40px;
            height: 40px;
            line-height: 1.2;
            &.p-disabled {
                color: $text-input-color;
            }

            &.p-highlight {
                @extend .rounded-circle;
                background-color: $color-main;
                color: $white !important;
                &:hover {
                    @extend .border-0;
                    background-color: $color-main-hover;
                }
            }
        }
        td {
            span {
                &:hover {
                    @extend .rounded-circle;
                    border: 2px solid $color-main;
                }
            }
        }

        > tbody > tr > td > span:not(.p-disabled) {
            cursor: pointer;
        }

        > tbody > tr > td.p-datepicker-today > span:not(.p-highlight) {
            background-color: $color-main;
            @extend .rounded-circle, .text-white;
            &:hover {
                @extend .border-0;
                background-color: $color-main-hover;
            }
        }
    }
}
.p-timepicker > div > button {
    @extend .w-100, .text-center;
}
.ck-powered-by{
    display: none;
}
.ck-editor__editable_inline {
    max-height: 600px;
    min-height: 400px;
}
// pagination
.p-paginator {
    background-color: #fbfbfb;
    height: 55px;
    @extend .py-2, .border-top, .border-bottom, .d-flex, .justify-content-center, .align-items-center;
    .p-paginator-pages {
        width: auto;
        .p-paginator-page {
            @extend .rounded-circle;
            border: 0px;
            width: 40px;
            height: 40px;
            color: $text-color;
            margin-left: 5px;
            margin-right: 4px;
        }
    }
    button:disabled {
        opacity: 0.3;
        -ms-filter: alpha(opacity=30);
    }
    .p-paginator-element {
        margin: 0px 4px;
        width: 40px;
        height: 40px;
        @extend .rounded-circle, .mb-2;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }
    .p-paginator-icon {
        display: none;
    }
    .p-paginator-next {
        background-image: url('../image/pagination-right-arrow.svg');
        &:not(.p-highlight):hover {
            background-image: url('../image/pagination-right-arrow.svg') !important;
        }
    }
    .p-paginator-prev {
        background-image: url('../image/pagination-left-arrow.svg');
        &:not(.p-highlight):hover {
            background-image: url('../image/pagination-left-arrow.svg') !important;
        }
    }
    .p-paginator-first {
        background-image: url('../image/pagination-back-forward.svg');
        &:not(.p-highlight):hover {
            background-image: url('../image/pagination-back-forward.svg') !important;
        }
    }
    .p-paginator-last {
        background-image: url('../image/pagination-step-forward.svg');
        &:not(.p-highlight):hover {
            background-image: url('../image/pagination-step-forward.svg') !important;
        }
    }
    .p-highlight {
        @extend .rounded-circle;
        background-color: $blue !important;
        border-color: $blue !important;
        height: 30px;
        width: 30px;
        line-height: 1.2;
        color: $white !important;
    }
    .p-highlight:hover {
        color: $white !important;
    }
}