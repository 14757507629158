.home-auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;

    /**
    * About Us
    */

    .about-us-row {
        margin-bottom: 60px;
    }

    .about-us-row .content-column .inner-column {
        position: relative;
        background-color: #f8f5f0;
        padding: 65px 60px 20px 70px;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 1199.98px) {
        .about-us-row .content-column .inner-column {
            padding: 65px 60px 20px 70px;
        }
    }

    @media (max-width: 991.98px) {
        .about-us-row .content-column .inner-column {
            padding-left: 40px;
            padding-right: 0;
        }
    }

    @media (max-width: 575.98px) {
        .about-us-row .content-column .inner-column {
            padding: 61px 40px 70px;
            margin-top: 0;
        }
    }

    .about-us-row .content-column .inner-column:before {
        content: '';
        position: absolute;
        top: 0;
        width: 95px;
        height: 11px;
        background-color: $theme-color1;
    }

    .about-us-row .content-column .inner-column .sec-title {
        margin-bottom: 20px;
    }

    .about-us-row .content-column .inner-column .sec-title .text {
        margin-top: 14px;
        -webkit-line-clamp: 10; /* Số dòng muốn hiển thị */
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        min-height: 200px;
    }

    .about-us-row .content-column .inner-column .sec-title .sub-title {
        margin-bottom: 11px;
        margin-left: 50px;
    }
    @media (max-width: 1199.98px) {
        .about-us-row .content-column .inner-column .sec-title h2 {
            font-size: 40px;
        }

        .about-us-row .content-column .inner-column .sec-title .text {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .about-us-row:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 70%;
        }
    }

    @media only screen and (max-width: 991px) {
        .about-us-row .content-column .inner-column {
            margin-top: 0;
        }

        .about-us-row .content-column .inner-column:before {
            left: 40px;
        }

        .about-us-row .image-column .inner-column {
            margin-right: 0;
        }

        .about-us-row:nth-child(2n) .image-column .inner-column {
            margin: 0;
        }

        .about-us-row:nth-child(2n) .content-column {
            order: 1;
        }

        .about-us-row:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 100%;
        }

        .about-us-row:nth-child(2n) .content-column .inner-column {
            padding: 61px 40px 70px;
        }
    }

    @media only screen and (max-width: 576px) {
        .about-us-row .image-column .inner-column {
            margin-bottom: 20px;
        }

        .about-us-row:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 100%;
        }
    }

    @media only screen and (max-width: 480px) {
        .about-us-row:nth-child(2n) .content-column .inner-column {
            padding: 61px 40px 70px;
        }
    }

    /**
    * Rooms suites
    */

    .left-content {
        .image-column .card {
            margin-right: -130px;
            margin-left: 0px !important;
        }
    }
    .room-suites {
        margin-bottom: 60px;
        .content-column {
            display: flex;
            align-items: center;
            z-index: 10;
        }
        .image-column .card {
            margin-left: -130px;
        }
        .p-galleria-caption {
            display: none;
        }
        .p-galleria-indicators {
            margin-bottom: 0 !important;
            background: none;
            button {
                border-radius: 50%;
            }
        }
    }

    .room-suites .content-column .inner-column {
        position: relative;
        background-color: #f8f5f0;
        padding: 40px 60px 70px 50px;
        width: 100%;
        height: 70%;
    }

    @media (max-width: 1199.98px) {
        .room-suites .content-column .inner-column {
            padding: 65px 60px 20px 70px;
        }
    }

    @media (max-width: 991.98px) {
        .room-suites .content-column {
            align-items: start;
        }
        .room-suites .content-column .inner-column {
            padding-left: 40px;
            padding-right: 0;
            height: 90%;
        }
        .room-suites .image-column .card {
            margin-right: 0px !important;
            margin-left: 0px !important;
        }
    }

    @media (max-width: 575.98px) {
        .room-suites .content-column .inner-column {
            margin-top: 0;
            padding: 30px 8px 40px;
        }
    }

    .room-suites .content-column .inner-column:before {
        content: '';
        position: absolute;
        top: 0;
        width: 95px;
        height: 11px;
        background-color: $theme-color1;
    }

    .room-suites .content-column .inner-column .sec-title {
        margin-bottom: 20px;
    }

    .room-suites .content-column .inner-column .sec-title .text {
        margin-top: 14px;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        min-height: 150px;
        margin-bottom: 10px;
    }

    .room-suites .content-column .inner-column .sec-title .sub-title {
        margin-bottom: 11px;
        margin-left: 50px;
    }
    @media (max-width: 1199.98px) {
        .room-suites .content-column .inner-column .sec-title h2 {
            font-size: 40px;
        }

        .room-suites .content-column .inner-column .sec-title .text {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .room-suites:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 70%;
        }
    }

    @media only screen and (max-width: 991px) {
        .room-suites .content-column .inner-column {
            margin-top: 0;
        }

        // .room-suites .content-column .inner-column:before {
        //     left: 40px;
        // }

        .room-suites .image-column .inner-column {
            margin-right: 0;
        }

        .room-suites:nth-child(2n) .image-column .inner-column {
            margin: 0;
        }

        .room-suites:nth-child(2n) .content-column {
            order: 1;
        }

        .room-suites:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 100%;
        }

        // .room-suites:nth-child(2n) .content-column .inner-column {
        //     padding: 61px 40px 70px;
        // }
    }

    @media only screen and (max-width: 576px) {
        .room-suites .image-column .inner-column {
            margin-bottom: 20px;
        }

        .room-suites:nth-child(2n) .content-column .inner-column .sec-title {
            max-width: 100%;
        }
    }

    // @media only screen and (max-width: 480px) {
    //     .room-suites:nth-child(2n) .content-column .inner-column {
    //         padding: 61px 40px 70px;
    //     }
    // }

    // Title
    .sec-title {
        position: relative;
        margin-bottom: 20px;
    }

    .sec-title.white .sub-title:before {
        display: none;
    }

    .sec-title.white h2 {
        color: #fff;
    }

    .sec-title .sub-title {
        position: relative;
        font-size: 18px;
        color: var(--text-color);
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 14px;
        font-family: var(--text-font2);
        letter-spacing: 3.8px;
    }

    .sec-title .sub-title:before {
        left: -50px;
    }

    .sec-title .sub-title:after,
    .sec-title .sub-title:before {
        content: '';
        position: absolute;
        top: 10px;
        width: 37px;
        height: 10px;
        background-image: url(../image/home/before_after.png);
    }

    .sec-title .sub-title:after {
        left: 100%;
        margin-left: 5px;
    }
    .sec-title h2 {
        position: relative;
        display: block;
        font-size: 30px;
        color: #0e2a4e;
        font-family: var(--sec-title-font-family);
        font-weight: 800;
        margin-bottom: 0;
        z-index: 2;
    }

    .sec-title .text {
        margin-top: 24px;
    }

    @media only screen and (max-width: 1200px) {
        .sec-title h2 br,
        br {
            display: none;
        }

        .sec-title h2 {
            font-size: 44px;
            line-height: 52px;
        }
    }

    @media only screen and (max-width: 767px) {
        .sec-title h2 {
            font-size: 40px;
            line-height: 50px;
        }

        .sec-title .sub-title {
            letter-spacing: 5px;
        }
    }

    @media only screen and (max-width: 480px) {
        .sec-title .sub-title {
            letter-spacing: 3px;
        }
    }
    .common-back-ground {
        background-color: $bg-home;
        margin-bottom: 60px;
    }

    /*
    * cheap tours styles
    *
    */
    .border-item {
        border: 1px solid $color-main;
    }
    .border-round {
        border-radius: 6px !important;
    }
    .card {
        background-color: transparent !important;
        border: none !important;
        .p-carousel-indicators {
            margin-bottom: 0px;
        }
    }
    .news-block {
        margin-bottom: 30px;
    }

    .news-block .inner-box {
        // background-color: #fff;
        position: relative;
        .content-column {
            background-color: rgb(232 225 209) !important;
            padding: 19px 16px 10px 15px;
            width: 80%;
            margin-top: -49px;
            margin-inline: auto;
            z-index: 9 !important;
            position: relative;
            &:before {
                position: absolute;
                content: '';
                top: -12px;
                right: -12px;
                bottom: -12px;
                left: -12px;
                background-color: rgba(190, 169, 122, 0.26);
            }
        }
    }
    .news-block .inner-box .content-column button {
        border: none;
        max-height: 40px;
        min-width: 100px;
        padding-top: 5px !important;
    }
    .news-block .inner-box .content-column h5,
    h6 {
        max-width: 200px;
    }
    .news-block .inner-box .content-column h6 {
        opacity: 50%;
    }
    height: 100%;
    position: absolute;
    content: '';
    top: -12px;
    right: 0px;
    bottom: 0px;
    left: -12px;
    background-color: $color-main-shadow;
    z-index: -3;
}
.news-block .inner-box .image-box img {
    width: 100%;
}
.news-block .inner-box:hover .image-box img {
    transform: scale(1.1);
    transition: all 0.3s ease;
}
