.width-lg {
    min-width: 24px;
    min-height: 24px;
}
.width-md {
    min-width: 20px;
    min-height: 20px;
}
.width-xxl {
    min-width: 36px;
    min-height: 36px;
}
span.icon {
    @extend .d-inline-block;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    background-size: contain;
    &.user-login {
        @extend .width-lg;
        background-image: url("../image/admin/user-header.PNG");
    }
    &.menu {
        @extend .width-md;
        background-image: url("../image/admin/menu.svg");
    }
    &.accepted {
        @extend .width-md;
        background-image: url("../image/admin/accepted.svg");
    }
    &.rejected {
        @extend .width-md;
        background-image: url("../image/admin/rejected.svg");
    }
    &.search {
        @extend .width-md;
        background-image: url("../image/admin/search.svg");
    }
    &.edit {
        @extend .width-md;
        background-image: url("../image/admin/edit.svg");
    }
    &.delete {
        @extend .width-md;
        background-image: url("../image/admin/delete.svg");
    }
    &.detail {
        @extend .width-md;
        background-image: url("../image/admin/detail.svg");
    }
    &.calendar {
        @extend .width-md;
        background-image: url("../image/admin/calendar.svg");
    }
    &.bed {
        @extend .width-lg;
        background-image: url("../image/bed.svg");
    }
    &.bath {
        @extend .width-lg;
        background-image: url("../image/bath.svg");
    }
    &.warehouse {
        @extend .width-lg;
        background-image: url("../image/warehouse.svg");
    }
    &.spa {
        @extend .width-xxl;
        background-image: url("../image/spa.svg");
    }
    &.utensils {
        @extend .width-xxl;
        background-image: url("../image/utensils.svg");
    }
    &.bell-concierge {
        @extend .width-xxl;
        background-image: url("../image/bell-concierge.svg");
    }
    &.car {
        @extend .width-xxl;
        background-image: url("../image/car.svg");
    }
    &.swimming {
        @extend .width-xxl;
        background-image: url("../image/swimming.png");
    }
    &.wifi {
        @extend .width-xxl;
        background-image: url("../image/wifi.png");
    }
    &.laundry {
        @extend .width-xxl;
        background-image: url("../image/laundry.svg");
    }
    &.gym {
        @extend .width-xxl;
        background-image: url("../image/gym.svg");
    }
    &.bar {
        @extend .width-xxl;
        background-image: url("../image/bar.svg");
    }
    &.motorcycle {
        @extend .width-xxl;
        background-image: url("../image/motorcycle.svg");
    }
    &.restaurant {
        @extend .width-xxl;
        background-image: url("../image/restaurant.svg");
    }
    &.direction {
        @extend .width-lg;
        background-image: url("../image/direction.svg");
    }
    &.blueprint {
        @extend .width-lg;
        background-image: url("../image/blueprint.svg");
    }
    &.bathroom {
        @extend .width-lg;
        background-image: url("../image/bathroom.svg");
    }
    &.television {
        @extend .width-lg;
        background-image: url("../image/television.svg");
    }
    &.cat {
        @extend .width-lg;
        background-image: url("../image/cat.svg");
    }
    &.air-conditioner {
        @extend .width-lg;
        background-image: url("../image/air-conditioner.svg");
    }
    &.iron {
        @extend .width-lg;
        background-image: url("../image/iron.svg");
    }
    &.hair-dryer {
        @extend .width-lg;
        background-image: url("../image/hair-dryer.svg");
    }
    &.high-speed-wifi {
        @extend .width-lg;
        background-image: url("../image/high-speed-wifi.svg");
    }
    &.swimming-pools {
        @extend .width-lg;
        background-image: url("../image/swimming-pool.svg");
    }
    &.gyms {
        @extend .width-lg;
        background-image: url("../image/gyms.svg");
    }
    &.kitchen {
        @extend .width-lg;
        background-image: url("../image/kitchen.svg");
    }
    &.pet {
        @extend .width-lg;
        background-image: url("../image/dog.svg");
    }
    &.breakfast {
        @extend .width-lg;
        background-image: url("../image/breakfast.svg");
    }
    &.kettle {
        @extend .width-lg;
        background-image: url("../image/kettle.svg");
    }
    &.people {
        @extend .width-lg;
        background-image: url("../image/people.svg");
    }
    &.outdoor {
        @extend .width-lg;
        background-image: url("../image/outdoor.svg");
    }
}
