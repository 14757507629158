@media screen and (max-width: 1366px) {
    .hide-item {
        display: none;
    }
    .nav-link {
        font-size: 15px !important;
    }
    .contact-with .icons {
        background-color: transparent;
    }
}
@media screen and (max-width: 768px) {
    .gallery {
        .p-tabview-panel,
        .img-big {
            order: initial;
        }
        .image-about {
            margin-left: 0px !important;
        }
    }
    .img-min-screen {
        img {
            width: 100%;
            height: 100%;
        }
    }
    .content-about {
        padding-top: 16px;
        padding-left: 16px !important;
    }
    .gallery-about {
        padding-left: 16px !important;
        padding-right: 16px !important;
        .img-gs,
        .img-ge,
        .img-right,
        .img-left,
        .image-about {
            margin-bottom: 8px;
        }
    }
    .single-event {
        padding: 40px 0;
        position: relative;
        .event-img {
            float: none;
            margin: 0 0 20px;
            img {
                width: 100%;
            }
        }
        .event-content {
            padding: 0;
            position: inherit;
        }
        .event-date {
            background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
            color: #fff;
            height: 100px;
            position: absolute;
            padding-top: 18px;
            top: -120px;
            width: 100px;
            span {
                font-size: 30px;
                padding-bottom: 0;
            }
        }
        .detail-event-date {
            top: 82px !important;
        }
        .event-details > h3 {
            font-size: 20px;
        }
    }
    .w-table-large,
    .w-table-small {
        width: 100%;
    }
}
