.wp-75 {
    width: 75%;
}
.fp-24 {
    font-size: 24px;
}
.text-red-main {
    color: $bg-red-main;
}
.text-blue-main {
    color: $bg-blue-main;
}
.fp-16 {
    font-size: 16px;
}
