.contact-with {
    background-color: #F8F6F3;
    margin: 0px;
    padding: 91px 65px 40px 56px;
    .subtitle {
        margin: 0 0 15px;
        font-size: 18px;
        font-size: 16px;
        color: #c19d68;
        font-weight: 500;
        text-transform: uppercase;
    }
    .title {
        margin: 0 0 0;
        font-size: 38px;
        line-height: 47px;
        text-transform: uppercase;
        color: #1e1e1e;
    }
    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin: 12px 0;
        width: 93%;
    }
    .icons {
        display: inline-block;
        background: #ffff;
        color: #c19d68;
        width: 65px;
        height: 65px;
        text-align: center;
        line-height: 65px;
        border-radius: 50%;
        margin-right: 18px;
        transition: .5s;
    }
    .title-sub {
        font-size: 15px;
        color: #616161;
        margin: 0 0 4px;
    }
    .description-sub {
        font-size: 20px;
        color: #1e1e1e;
        font-weight: 300;
        margin: 0;
    }
    .bg-contact {
        background-color: $color-main;
    }
    .btn-action {
        background-color: $color-main-hover !important;
        min-width: 100% !important;
    }
    .form-control{
        background-color: $color-main;
        border: 1px solid $color-main-hover;
        color: white;
        &::placeholder {
            color: white;
          }
    }
}